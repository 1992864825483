import * as React from "react"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '../components/layout'
import { Helmet } from "react-helmet";

// markup
const IndexPage = () => {


  const { workPhoto, servicesPhoto, aboutPhoto } = useStaticQuery(
    graphql`
      query {
        workPhoto: file(relativePath: { eq: "home-work-1320x1600-46329346460.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              layout: CONSTRAINED
            )
          }
        }
        servicesPhoto: file(relativePath: { eq: "home-services-1320x1600-46329375249.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              layout: CONSTRAINED
            )
          }
        }
        aboutPhoto: file(relativePath: { eq: "home-about-1320x1600-63059877294.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              layout: CONSTRAINED
            )
          }
        }
      }
      `
  );



  return (

    <Layout pageTitle="Home">

      <Helmet>
        <link rel="stylesheet" href="/homehero.css" />
      </Helmet>

      <section className="pt-10 pt-md-10 pb-10 pb-md-10 overlay overlay-black overlay-60 bg-cover homehero">

        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-11 col-lg-11 text-center">

              <h1 className="display-1 fw-bold text-white text-shadow">
                David Baxter
              </h1>

              <h3 className="text-white-75 mb-4 text-shadow">
                Video producer, live stream engineer, and all-around tech nerd.
              </h3>

            </div>
          </div>
        </div>
      </section>



      <div className="position-relative">
        <div className="shape shape-bottom shape-fluid-x text-light">
          <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z" fill="currentColor" /></svg>      </div>
      </div>






      <section className="pb-8 pt-7 pb-md-11 pt-md-10 bg-gradient-light-white">
        <div className="container">
          <div className="row">



            <div className="col-12 col-md-6 col-lg-4 d-flex">
              <Link to="/work/" className="card mb-6 mb-lg-0 pt-14 overlay overlay-black overlay-30 bg-cover shadow-light-lg lift lift-lg" style={{ background: `url(${workPhoto.childImageSharp.gatsbyImageData.images.fallback.src})`, backgroundPosition: "center", backgroundSize: "cover" }}>
                <span className="card-body mt-auto mb-auto">
                  <h3 className="text-white text-center display-3">
                    My Work
                  </h3>
                  <p className="mb-0 text-center text-white">
                    Explore my past projects.
                  </p>
                </span>
              </Link>
            </div>



            <div className="col-12 col-md-6 col-lg-4 d-flex">
              <Link to="/services/" className="card mb-6 mb-lg-0 pt-14 overlay overlay-black overlay-30 bg-cover shadow-light-lg lift lift-lg" style={{ background: `url(${servicesPhoto.childImageSharp.gatsbyImageData.images.fallback.src})`, backgroundPosition: "center", backgroundSize: "cover" }}>
                <span className="card-body mt-auto mb-auto">
                  <h3 className="text-white text-center display-3">
                    Services
                  </h3>
                  <p className="mb-0 text-center text-white">
                    See what I can do.
                  </p>
                </span>
              </Link>
            </div>



            <div className="col-12 col-md-6 col-lg-4 d-flex">
              <Link to="/about/" className="card mb-6 mb-lg-0 pt-14 overlay overlay-black overlay-30 bg-cover shadow-light-lg lift lift-lg" style={{ background: `url(${aboutPhoto.childImageSharp.gatsbyImageData.images.fallback.src})`, backgroundPosition: "center", backgroundSize: "cover" }}>
                <span className="card-body mt-auto mb-auto">
                  <h3 className="text-white text-center display-3">
                    About Me
                  </h3>
                  <p className="mb-0 text-center text-white">
                    Learn more about who I am.
                  </p>
                </span>
              </Link>
            </div>






          </div>
        </div>
      </section>




    </Layout>


  )
}

export default IndexPage
